body {
  margin: 0;
  font-family:'Ubuntu Condensed' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code, a, div, p, input, span {
  font-family: 'Ubuntu Condensed' !important;
}
.toolbarLink{
  font-size: 16px;
  padding: 8px;
  text-transform: uppercase;
  text-decoration: none;
  color: #FFFFFF;
}

.pageLink{
  font-size: 16px;

  text-transform: uppercase;
  text-decoration: none;
  color: #000000;
}
.toolbarLogo{
max-height: 40px;



}
